<template>
  <v-card
    elevation="2"
    :disabled="isDisabled"
  >
    <v-card-title>{{ $t('comment') }}</v-card-title>
    <v-card-subtitle class="error--text">
      {{ $t('workInProgressAddingCommentWillNotBeIncludedWithBooking') }}
    </v-card-subtitle>
    <v-card-text>
      <v-textarea
        :label="$t('comment')"
        :placeholder="$t('addComment')"
        v-model="comment"
      />
    </v-card-text>
  </v-card>
</template>

<script>
	export default {
		name: "CommentCard",
		props: {
			isDisabled: {
        type: Boolean
      }
		},
		data() {
			return {
				comment: null
			}
		},
		watch: {
			comment(value) {
				this.$emit('updated', value)
			}
		}
	}
</script>

<style scoped>

</style>